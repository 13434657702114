/* https://stackoverflow.com/questions/3007480/determine-if-user-navigated-from-mobile-safari */
function iOSSafari(userAgent) {
  return (
    /iP(ad|od|hone)/i.test(userAgent) &&
    /WebKit/i.test(userAgent) &&
    !/(CriOS|FxiOS|OPiOS|mercury)/i.test(userAgent)
  );
}

function version(userAgent) {
  var matches = userAgent.match(/OS ([0-9]+)_/);
  if (!matches || matches.length < 2) return;
  try {
    return parseInt(matches[1], 10);
  } catch (e) {}
}

var v = version(navigator.userAgent);

if (iOSSafari(navigator.userAgent) && v && v <= 13) {
  alert(
    "You are using a version of mobile Safari that is not supported. You need to upgrade to a newer version of iOS (you must be using at least iOS 14).",
  );
}
